// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-list {
  bottom: 0;
  height: 96px;
  width: 100%;
  background-color: black;
  color: white;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-item a, .tab-list-item a:visited, .tab-list-item a:hover, .tab-list-item a:active {
  color: inherit;
}

.tab-list-active {
  height: 100%;
  background-color: white;
  color: black;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.header{
  height: 96px;
  width: 100%;
  background-color: black;
  color: white
}

.content{
  height: 100%;

}  


.app {
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.logo{
  height: 48px;
  /* width: 48px; */
  margin:24px;
}

.map{
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB;AACF;;AAEA;EACE,YAAY;;AAEd;;;AAGA;EACE,aAAa;EACb,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".tab-list {\n  bottom: 0;\n  height: 96px;\n  width: 100%;\n  background-color: black;\n  color: white;\n}\n\n.tab-list-item {\n  display: inline-block;\n  list-style: none;\n  margin-bottom: -1px;\n  padding: 0.5rem 0.75rem;\n}\n\n.tab-list-item a, .tab-list-item a:visited, .tab-list-item a:hover, .tab-list-item a:active {\n  color: inherit;\n}\n\n.tab-list-active {\n  height: 100%;\n  background-color: white;\n  color: black;\n  border: solid #ccc;\n  border-width: 1px 1px 0 1px;\n}\n\n.header{\n  height: 96px;\n  width: 100%;\n  background-color: black;\n  color: white\n}\n\n.content{\n  height: 100%;\n\n}  \n\n\n.app {\n  height: 100vh;\n  width: 100%;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  margin: 0 auto;\n}\n\n.logo{\n  height: 48px;\n  /* width: 48px; */\n  margin:24px;\n}\n\n.map{\n  height: 100%;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
