
import homeimg from "../mockup/home.png";


const HomePage = () => {
    return (
        <div>
            {/* <h1>Home</h1>; */}
            <img src={homeimg} />
        </div>
    )
  };
  
  export default HomePage;
  